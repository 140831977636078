<template>
  <div class="tabs">
    <div class="tab-item"
      v-for="(item) in tabs"
      :key="item.key"
      :class="[activeKey === item.key ? 'active': '']"
      :style="{color:  activeKey === item.key || activeColor}"
      @click="isTabClick(item.key)"
    >{{item.label}}</div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeKey: {
      type: String,
      default: '0'
    },
    activeColor: {
      type: String,
      default: '#202327'
    }
  },

  methods: {
    isTabClick(key) {
      this.$emit('active', key)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>