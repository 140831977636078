<template>
  <div class="login-wrap">
    <div class="logo">
      <img src="../../assets/imgs/logo.png">
    </div>
    <div class="version-wrap">V1.0.0</div>
    <template v-if="!isForget && !register">
      <div class="login-box">
        <LoginCard
          @forget="isForgetClick"
          @login="isLogin"
          @register="isRegisterClick"
        />
      </div>
    </template>
    <template v-else-if="isForget">
      <div class="login-forget">
        <ForgotCard
          @back="isBack"
          @save="isSave"
        />
      </div>
    </template>
    <template v-else-if="register">
      <div class="register">
        <RegisterCard
          @back="isHide"
          @forward="isNotDirectReg"
          :direct="direct"
        />
      </div>
    </template>
    <div class="login-footer">
      <div class="copyright">沪ICP备13022450号-12 工信部备案管理系统网址<a href="https://beian.miit.gov.cn/" target="blank">（BEIAN.MIIT.GOV.CN）</a></div>
      <div class="desc">沪公网安备<a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502018629" target="blank">31011502018629</a>号</div>
    </div>
  </div>
</template>

<script>
import {
  fetchLogin,
  loginVerifyCode,
  fetchNotDirectRegister,
  fetchResetPassword,
  fetchDirectRegister
} from '@/api'
import LoginCard from './components/LoginCard'
import ForgotCard from './components/ForgotCard'
import RegisterCard from './components/RegisterCard'
import { Message, Modal } from 'view-design'
import { mapMutations } from 'vuex'
import { getMenuLists } from '@/store/header'
export default {
  name: 'Login',

  data () {
    return {
      isForget: false,
      register: false,
      direct: true,
      prevMobile: '',
      prevAuto: false
    }
  },

  components: {
    LoginCard,
    ForgotCard,
    RegisterCard
  },

  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },

  computed: {},

  methods: {
    ...mapMutations('user', ['setToken', 'setUserId', 'setRole']),
    ...mapMutations('header', ['setMenuLists']),

    isForgetClick() {
      // this.isForget = true
      Modal.info({
        title: '忘记密码',
        content: '请联系管理员重置密码',
        okText: '知道了'
      })
    },
    isBack() {
      this.isForget = false
    },
    isHide() {
      this.register = false
    },
    async isSave(form) {
      const res = await fetchResetPassword(form)
      const { message } = res
      if (message === 'success') {
        // 密码重置 接口
        Message.success({
          content: '密码重置成功',
          duration: 2
        })
        this.isForget = false
      } else {
        Message.warning({
          content: message,
          duration: 2
        })
      }
    },
    async isNotDirectReg({ form, part }) {
      const { name, company, office, position, department, part: job, password, ensure, mail, verify, mobile } = form
      const option = {
        mobile: this.prevMobile || mobile.value,
        name: name.value.trim(),
        company_name: company.value.trim(),
        company_level: company.company_level,
        province: company.province,
        company_type: part === 'hospital'
          ? '1'
          : part === 'unit'
            ? '2'
            : '3',
        department: part === 'hospital'
          ? office.value
          : part === 'unit'
            ? department.value
            : undefined,
        position: part === 'hospital'
          ? position.value
          : part === 'unit'
            ? job.value
            : undefined,
        password: password.value,
        confirm_password: ensure.value,
        email: mail.value || undefined,
        sms: this.direct ? verify.value : undefined
      }
      let res
      if (!this.direct) {
        res = await fetchNotDirectRegister(option)
      } else {
        res = await fetchDirectRegister(option)
      }
      if (res.code === 9) {
        Message.error({
          content: res.message,
          duration: 2
        })
        return
      }
      this.setCommonTokenAndNav(res)
    },
    setCommonTokenAndNav({ data }) {
      const { token, id, permissions } = data
      // store设置token
      localStorage.setItem('userID', id)
      localStorage.setItem('menu', permissions)
      const temp = getMenuLists(permissions)

      this.setMenuLists(temp)
      this.setUserId(id)
      this.setToken({ token, expires: this.prevAuto ? 90 : 1 })

      // this.setRole()

      const { query: { redirect } } = this.$route
      redirect
        ? this.$router.replace({ path: redirect })
        : this.$router.push({
          path: '/literature'
        })
      // }
    },
    async isLogin({ info, tabKey }) {
      const { password, mobile, auto, auth } = info
      this.prevAuto = auto

      const key = tabKey === '0' ? 'username' : 'mobile'

      const option = {
        [key]: mobile,
        password: password,
        exp_time: auto ? '90' : '1',
        captcha: auth || undefined
      }

      let res
      if (tabKey === '0') {
        res = await fetchLogin(option)
        const { code, message } = res
        if (code === 9) {
          Message.warning({
            content: message,
            duration: 2
          })
        } else {
          this.setCommonTokenAndNav(res)
        }
      } else {
        // 验证码登陆逻辑
        res = await loginVerifyCode(option)
        const { data, message } = res
        if (data) {
          const { status, is_banned } = data
          if (is_banned) {
            Message.warning({
              content: '账户已被禁用，请联系管理员！',
              duration: 2
            })
          } else {
            if (!status) {
              this.register = true
              this.direct = false
              this.prevMobile = mobile
            } else {
              this.setCommonTokenAndNav(res)
            }
          }
        } else {
          Message.warning({
            content: message,
            duration: 1
          })
        }
      }
    },

    isRegisterClick() {
      // this.register = true
      // this.direct = true
      Modal.info({
        title: '注册',
        content: '请先到企业微信-MSA模块登陆',
        okText: '知道了'
      })
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
