<template>
  <div class="register-wrap">
    <div class="flex-wrap title">新用户注册</div>
    <div class="register-wrap-form">
      <FormItem :form="form.name" />
      <FormItem :form="form.company" @change="isChange" @company="isCompanyClick"/>
      <template v-if="direct">
        <FormItem :form="form.mobile" @blur="validateMobile"/>
        <FormItem :form="form.verify"
          :show="isShowCode"
          :count="countDown"
          :time="total"
          :timer="timer"
          @code="isVerifyCode"
          @blur="validateCode"
        />
      </template>
      <template v-if="part==='hospital'">
        <FormItem :form="form.office" type="select" />
        <FormItem :form="form.position" type="select" />
      </template>
      <template v-if="part==='unit'">
        <FormItem :form="form.department" type="select" />
        <FormItem :form="form.part" type="select" />
      </template>
      <FormItem :form="form.password" @blur="validateWord" />
      <FormItem :form="form.ensure" @blur="validateEnsure" />
      <FormItem :form="form.mail" />
      <div class="announce">
        <Checkbox v-model="announce">
          <span>我已阅读并同意<span class="info">《XXXXXXXXXXX医药平台规范声明》</span></span>
        </Checkbox>
      </div>
    </div>
    <div class="flex-wrap forget-footer">
      <Button class="back" type="text" @click="isBack">返回</Button>
      <Button type="primary" @click="isRegister">注册</Button>
    </div>
  </div>
</template>

<script>
import { fetchInstitute, fetchVerifyCode } from '@/api'
import { Button, Checkbox, Message } from 'view-design'
import FormItem from '../FormItem'
import { form } from './config'
import cloneDeep from 'lodash/cloneDeep'
import { limitInputValue } from '@/utils/tools'
export default {
  name: 'RegisterCard',

  props: {
    direct: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: cloneDeep(form),
      announce: false,
      loading: false,
      part: '',
      // 获取验证码相关
      isShowCode: false,
      countDown: 0,
      total: 60,
      timer: null
    }
  },

  components: {
    Button,
    FormItem,
    Checkbox
  },

  created() {
    console.log(this.direct, 'direct')
  },

  methods: {
    isBack() {
      // 重置表单
      this.form = cloneDeep(form)
      this.$emit('back')
    },
    commonMethods(target, key) {
      const { reg, value } = target
      if (!value) {
        this.form[key].show = false
      } else if (!reg.test(value)) {
        this.form[key].show = true
      } else {
        this.form[key].show = false
      }
    },
    validateWord() {
      this.commonMethods(this.form.password, 'password')
    },
    validateEnsure() {
      this.commonMethods(this.form.ensure, 'ensure')
    },
    validateMobile() {
      this.commonMethods(this.form.mobile, 'mobile')
    },
    validateCode() {
      this.commonMethods(this.form.verify, 'verify')
    },
    isCompanyClick(item) {
      this.form.company.value = item.company_name
      this.form.company.company_level = item.company_level
      this.form.company.province = item.province
      this.form.company.list = []
      if (this.form.company.value.indexOf('医院') !== -1) {
        this.part = 'hospital'
      } else if (this.form.company.value.indexOf('公司') !== -1) {
        this.part = 'unit'
      } else {
        this.part = ''
      }
    },
    async isChange(val) {
      if (!val.trim()) {
        this.form.company.list = []
        this.part = ''
      } else {
        const res = await fetchInstitute({ keyword: val }, false)
        this.form.company.list = res.data
        if (!res.length) {
          this.part = ''
        }
      }
    },
    isRegister() {
      let labels = []
      const validates = []
      console.log(this.form, 'form')
      Object.keys(this.form).forEach(key => {
        const item = this.form[key]
        if (item.required && !item.value) {
          labels.push(item.label)
        }
        if (item.show) {
          validates.push(item.label)
        }
      })

      // 手机验证码登陆 去除手机验证码校验
      if (!this.direct) {
        labels = labels.filter(v => v !== '手机号码' && v !== '验证码')
      }

      if (this.part === 'hospital') {
        labels = labels.filter(v => v !== '部门' && v !== '职位')
      } else if (this.part === 'unit') {
        labels = labels.filter(v => v !== '科室' && v !== '职称')
      } else {
        labels = labels.filter(v => v !== '部门' && v !== '职位' && v !== '科室' && v !== '职称')
      }

      if (labels.length) {
        Message.warning({
          content: `${labels[0]}不能为空！`,
          duration: 2
        })
      } else if (validates.length) {
        Message.warning({
          content: `${validates[0]}格式错误！`,
          duration: 2
        })
      } else {
        const { password, ensure } = this.form
        if (password.value !== ensure.value) {
          Message.warning({
            content: '两次密码不一致！',
            duration: 2
          })
        } else if (!this.announce) {
          Message.warning({
            content: '请勾选阅读声明！',
            duration: 2
          })
        } else {
          const { name, company } = this.form
          if (limitInputValue(name.value) > 100 || !name.value.trim()) {
            Message.warning({
              content: '姓名长度为1-100！',
              duration: 2
            })
          } else if (limitInputValue(company.value) > 100 || !company.value.trim()) {
            Message.warning({
              content: '单位长度为1-100！',
              duration: 2
            })
          } else {
            // 发完倒计时验证逻辑存在问题！
            this.$emit('forward', {
              form: this.form,
              part: this.part
            })
          }
        }
      }
    },
    async getVerifyCode(option) {
      const res = await fetchVerifyCode(option)
      if (res.message === 'success') {
        Message.success({
          content: '验证码已发送!',
          duration: 2
        })
        this.isShowCode = true
        if (!this.timer) {
          this.countDown = this.total
          this.timer = setInterval(() => {
            if (this.countDown > 0 && this.countDown <= this.total) {
              this.countDown--
            } else {
              this.isShowCode = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      } else {
        Message.error({
          content: res.message,
          duration: 2
        })
      }
    },
    // 获取验证码
    isVerifyCode() {
      if (this.form.mobile.value && !this.form.mobile.show) {
        this.getVerifyCode({ mobile: this.form.mobile.value })
      } else {
        Message.error({
          content: '请填写正确的手机号码！',
          duration: 2
        })
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>