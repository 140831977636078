export const tabs = [
  {
    key: '0',
    label: '密码登录'
  }
  // {
  //   key: '1',
  //   label: '验证码登录'
  // }
]

export const mobile = {
  label: '手机号码',
  value: '',
  placeholder: '请输入手机号',
  show: false,
  message: '请输入正确的11位手机号',
  // reg: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
  reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
}

export const account = {
  label: '账号',
  value: '',
  placeholder: '请输入账号',
  show: false,
  message: '请输入正确的账号',
  // reg: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
  reg: /\S/
}

export const password = {
  label: '密码',
  value: '',
  placeholder: '请输入密码',
  type: 'password',
  show: false,
  reg: /^[a-zA-Z0-9!]{6,16}$/,
  message: '密码应为6～16位字母、数字或符号'
}

export const auth = {
  label: '验证码',
  value: '',
  placeholder: '请输入验证码',
  isAuth: true,
  show: false,
  message: '请输入正确的6位验证码',
  reg: /^\d{6}$/
}

export const resetPassword = {
  label: '密码',
  value: '',
  placeholder: '请输入6-10位新密码',
  type: 'password',
  show: false,
  reg: /^[a-z0-9]{6,16}$/,
  message: '密码应为6～16位字母、数字或符号'
}

export const ensurePassword = {
  label: '确认密码',
  value: '',
  placeholder: '请再次输入新密码',
  type: 'password',
  show: false
}

export const registryMobile = {
  label: '手机号',
  value: '',
  placeholder: '请输入注册手机号码',
  show: false,
  message: '请输入正确的11位手机号',
  // reg: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
  reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
}

export const steps = [
  {
    label: '信息验证',
    value: '0'
  },
  {
    label: '密码重置',
    value: '1'
  }
]