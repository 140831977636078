<template>
  <div class="forget-wrap">
    <div class="flex-wrap title">密码重置</div>
    <div class="flex-wrap forget-steps">
      <CustomSteps
        :steps="steps"
        :current="current"
      />
    </div>
    <div class="flex-wrap forget-forms">
      <template v-if="current === '0'">
        <ForgotItem
          :options="registryMobile"
          @blur="isValidateMobile"
        />
        <ForgotItem
          :options="auth"
          :show="isShowCode"
          :count="countDown"
          :time="total"
          :timer="timer"
          @blur="isLoginCode"
          @code="isVerifyCode"
        />
      </template>
      <template v-else>
        <ForgotItem
          :options="resetPassword"
          @blur="isValidatePassword"
        />
        <ForgotItem
          :options="ensurePassword"
        />
      </template>
    </div>
    <div class="flex-wrap forget-footer">
      <Button class="back" type="text" @click="isBackLogin">返回</Button>
      <template v-if="current === '0'">
        <Button type="primary" @click="isNext">下一步</Button>
      </template>
      <template v-else>
        <Button type="primary" @click="isResetSave">保存</Button>
      </template>
    </div>
  </div>
</template>

<script>
import { fetchVerifyCode } from '@/api'
import { registryMobile, ensurePassword, resetPassword, auth, steps } from '../../config'
import ForgotItem from '../ForgotItem'
import CustomSteps from 'components/CustomSteps'
import { Button, Message } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'ForgotCard',
  data () {
    return {
      current: '0',
      registryMobile: cloneDeep(registryMobile),
      ensurePassword: cloneDeep(ensurePassword),
      resetPassword: cloneDeep(resetPassword),
      auth: cloneDeep(auth),
      steps,
      isShowCode: false,
      countDown: 0,
      total: 60,
      timer: null
    }
  },

  components: {
    ForgotItem,
    CustomSteps,
    Button
  },

  computed: {},

  methods: {
    // isSteps(val) {
    //   this.current = val
    // },
    commonMethods(target, key) {
      const { reg, value } = target
      const val = value.trim()
      if (!val) {
        this[key].show = false
      } else if (!reg.test(val)) {
        this[key].show = true
      } else {
        this[key].show = false
      }
    },
    isValidateMobile() {
      this.commonMethods(this.registryMobile, 'registryMobile')
    },
    isValidatePassword() {
      this.commonMethods(this.resetPassword, 'resetPassword')
    },
    isLoginCode() {
      this.commonMethods(this.auth, 'auth')
    },
    async getVerifyCode(option) {
      const res = await fetchVerifyCode(option)
      if (res.message === 'success') {
        Message.success({
          content: '验证码已发送！',
          duration: 2
        })
        this.isShowCode = true
        if (!this.timer) {
          this.countDown = this.total
          this.timer = setInterval(() => {
            if (this.countDown > 0 && this.countDown <= this.total) {
              this.countDown--
            } else {
              this.isShowCode = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      } else {
        Message.warning({
          content: '手机号码未注册，请先注册！',
          duration: 2
        })
      }
    },
    // 获取验证码
    isVerifyCode() {
      if (this.registryMobile.value && !this.registryMobile.show) {
        this.getVerifyCode({ mobile: this.registryMobile.value })
      } else {
        Message.error({
          content: '请填写正确的手机号码！',
          duration: 2
        })
      }
    },
    isNext() {
      const { reg, value } = this.auth
      const mobile = this.registryMobile.value.trim()
      const auth = this.auth.value.trim()
      if (!mobile || !auth) {
        Message.warning({
          content: '请输入手机号和验证码！',
          duration: 2
        })
      } else if (!reg.test(value)) {
        this.auth.show = true
      } else {
        this.auth.show = false
        this.current = '1'
      }
    },
    isBackLogin() {
      this.registryMobile.value = ''
      this.registryMobile.show = false
      this.ensurePassword.value = ''
      this.resetPassword.value = ''
      this.resetPassword.show = false
      this.auth.value = ''
      this.auth.show = false
      this.$emit('back')
    },
    isResetSave() {
      const reset = this.resetPassword.value.trim()
      const sure = this.ensurePassword.value.trim()
      if (!reset || !sure) {
        Message.warning({
          content: '输入不能为空！',
          duration: 2
        })
      } else if (reset !== sure) {
        Message.warning({
          content: '请确保前后输入密码一致！',
          duration: 2
        })
      } else {
        const form = {
          captcha: this.auth.value,
          mobile: this.registryMobile.value,
          password: this.resetPassword.value,
          confirm_password: this.ensurePassword.value
        }
        this.$emit('save', form)
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>