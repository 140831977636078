<template>
  <div class="login-item">
    <div class="label">{{options.label}}</div>
    <div class="login-input">
      <div class="left">
        <Input
          v-model="options.value"
          :border="false"
          :placeholder="options.placeholder"
          :type="options.type"
          @on-enter="iskeyUpLogin(options.type)"
          @on-blur="validateInput"
        />
      </div>
      <template v-if="options.isAuth">
        <CountDown
          :show="show"
          :count="count"
          :timer="timer"
          :time="time"
          @code="isCode"
        />
      </template>
    </div>
    <div class="error" v-if="options.show">
      <span>{{options.message}}</span>
    </div>
  </div>
</template>

<script>
import { Input } from 'view-design'
import CountDown from 'components/CountDown'
export default {
  name: 'LoginItem',

  props: {
    options: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 10
    },
    timer: {
      type: null
    },
    time: {
      type: Number,
      default: 10
    }
  },

  components: {
    Input,
    CountDown
  },

  methods: {
    iskeyUpLogin(type) {
      if (type === 'password') {
        this.$emit('login')
      }
    },
    validateInput() {
      this.$emit('blur')
    },
    isCode() {
      this.$emit('code')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>