<template>
  <div class="forgot-item">
    <div class="forget-input-item">
      <div class="label">{{options.label}}</div>
      <div class="forgot-input">
        <div class="left">
          <Input
            v-model="options.value"
            :border="false"
            :placeholder="options.placeholder"
            :type="options.type"
            :password="isPassword"
            @on-blur="validate"
          />
        </div>
        <div class="vertify-code" v-if="options.isAuth">
          <CountDown
            :show="show"
            :count="count"
            :timer="timer"
            :time="time"
            @code="isCode"
          />
        </div>
      </div>
    </div>
    <div class="error" v-if="options.show">
      <span>{{options.message}}</span>
    </div>
  </div>
</template>

<script>
import { Input } from 'view-design'
import CountDown from 'components/CountDown'
export default {
  name: 'ForgotItem',

  props: {
    options: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 10
    },
    timer: {
      type: null
    },
    time: {
      type: Number,
      default: 10
    }
  },

  components: {
    Input,
    CountDown
  },

  computed: {
    isPassword() {
      return (this.options.label === '密码' || this.options.label === '确认密码')
    }
  },

  methods: {
    validate() {
      this.$emit('blur')
    },
    isCode() {
      this.$emit('code')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>