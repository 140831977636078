<template>
  <div class="login-card">
    <div class="login-header">
      <Tabs
        :tabs="tabs"
        :activeKey="tabKey"
        @active="isActive"
      />
    </div>
    <div class="login-content">
      <template v-if="tabKey === '0'">
        <LoginItem
          :options="account"
          @blur="isLoginAccount"
        />
      </template>
      <template v-if="tabKey === '1'">
        <LoginItem
          :options="mobile"
          @blur="isLoginMobile"
        />
      </template>
      <template v-if="tabKey === '0'">
        <LoginItem
          :options="password"
          @blur="isLoginPassWord"
          @login="isLoginClick"
        />
      </template>
      <template v-else>
        <LoginItem
          :options="auth"
          :show="isShowCode"
          :count="countDown"
          :time="total"
          :timer="timer"
          @blur="isLoginCode"
          @code="isVerifyCode"
        />
      </template>
      <div class="common">
        <div class="left">
          <Checkbox v-model="isAuto">
            <span>90天内自动登录</span>
          </Checkbox>
        </div>
        <div class="right">
          <span @click="isForgetClick">忘记密码</span>
          <Divider type="vertical" />
          <span @click="isRegister">注册</span>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <div class="login-btn" @click="isLoginClick">登录</div>
      <div class="login-info">
        <span>智能医学平台</span>
        <Divider type="vertical" />
        <span>火石数智</span>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchVerifyCode } from '@/api'
import { tabs, mobile, password, auth, account } from '../../config'
import Tabs from 'components/Tabs'
import LoginItem from '../LoginItem'
import { Divider, Checkbox, Message } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'LoginCard',
  data () {
    return {
      tabs,
      tabKey: '0',
      isAuto: false,
      mobile: cloneDeep(mobile),
      password: cloneDeep(password),
      auth: cloneDeep(auth),
      account: cloneDeep(account),
      // 获取验证码相关
      isShowCode: false,
      countDown: 0,
      total: 60,
      timer: null
    }
  },

  components: {
    Tabs,
    Divider,
    Checkbox,
    LoginItem
  },

  computed: {
    isNotSuit({ account, mobile, auth, password, tabKey }) {
      if (tabKey === '0') {
        return !account.value || mobile.show || !password.value || password.show
      } else {
        return !mobile.value || mobile.show || !auth.value || auth.show
      }
    }
  },

  methods: {
    commonReset(key) {
      this[key].value = ''
      this[key].show = false
    },
    // tab切换重置状态
    isActive(key) {
      this.commonReset('mobile')
      this.commonReset('auth')
      this.password.value = ''
      this.isAuto = false
      this.tabKey = key
    },
    commonValidate(target, key) {
      const { reg, value } = target
      if (!value) {
        this[key].show = true
      } else if (!reg.test(value)) {
        this[key].show = true
      } else {
        this[key].show = false
      }
    },
    isLoginMobile() {
      if (this.mobile.value !== process.env.VUE_APP_COMMON_MOBILE) {
        this.commonValidate(this.mobile, 'mobile')
      } else {
        this.mobile.show = false
      }
    },
    isLoginAccount() {
      if (this.mobile.value !== process.env.VUE_APP_COMMON_MOBILE) {
        this.commonValidate(this.account, 'account')
      } else {
        this.account.show = false
      }
    },
    isLoginPassWord() {
      this.commonValidate(this.password, 'password')
      // if (this.password.value !== process.env.VUE_APP_COMMON_STR) {
      // } else {
      //   this.password.show = false
      // }
    },
    isLoginCode() {
      this.commonValidate(this.auth, 'auth')
    },
    isLoginClick() {
      const info = {
        auto: this.isAuto,
        mobile: this.tabKey === '0' ? this.account.value : this.mobile.value
      }
      if (this.tabKey === '0') {
        info.password = this.password.value
      } else {
        info.auth = this.auth.value
      }

      console.log(info)
      if (this.isNotSuit) {
        Message.error({
          content: '请填写正确的账号信息！',
          duration: 2
        })
      } else {
        this.$emit('login', { info, tabKey: this.tabKey })
      }
    },
    isForgetClick() {
      // 重置表单
      this.commonReset('mobile')
      this.commonReset('auth')
      this.$emit('forget')
    },
    isRegister() {
      this.$emit('register')
    },
    async getVerifyCode(option) {
      const res = await fetchVerifyCode(option)
      const { message } = res
      if (message === 'success') {
        Message.success({
          content: '验证码已发送!',
          duration: 2
        })
        this.isShowCode = true
        if (!this.timer) {
          this.countDown = this.total
          this.timer = setInterval(() => {
            if (this.countDown > 0 && this.countDown <= this.total) {
              this.countDown--
            } else {
              this.isShowCode = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    // 获取验证码
    isVerifyCode() {
      if (this.mobile.value && !this.mobile.show) {
        this.getVerifyCode({ mobile: this.mobile.value })
      } else {
        Message.error({
          content: '请填写正确的手机号码！',
          duration: 2
        })
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>