<template>
  <div class="custom-steps">
      <!-- @click="handleSteps(item.value)" -->
    <div class="step-item"
      v-for="(item, index) in steps"
      :key="item+index"
      :class="{ 'step-active': item.value === current }"
    >
      <div class="step-num">{{index+1}}</div>
      <div class="step-info">{{item.label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSteps',

  props: {
    steps: {
      type: Array,
      default: () => []
    },
    current: {
      type: String,
      default: '0'
    }
  },

  methods: {
    // handleSteps(val) {
    //   this.$emit('step', val)
    // }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>